@keyframes app-loading {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.loading{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
}
.loading div {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
    width: 80px;
    height: 80px;
}

.loading div div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: app-loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div div:nth-child(2) {
    animation-delay: -0.5s;
}

a {
    color: #284b68
}
a:hover{
    color: #1d405d
}